:root {
    --menu-toggle-color: var(--light-color);
}

.div-navbar {
    background: var(--dark-color);
    display: flex;
    font-size: 2rem;
	flex-direction: row;
    height: var(--navbar-height);
	position: absolute;
    top: 0;
    left: 0;
	width: 100%;
    z-index: 10;
}

.div-navbar > div {
    display: flex;
	height: 100%;
}

.div-navbar-floating {
	box-shadow: 0px 5px 10px #000;
}

.div-navbar-left {
    align-items: center;
	flex-direction: row;
    padding-left: 20px;
    width: 100%;
}

.div-navbar-right {
    align-items: center;
	flex-direction: row-reverse;
    padding-right: 20px;
    width: 100%;
}

.div-navbar-center {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    white-space: nowrap;
}

.div-navbar-center > a {
    color: var(--text-light);
    font-size: 2rem;
    text-decoration: none;
    text-shadow: var(--text-light-shadow);
}

.div-navbar-center > a:hover {
    color: #000;
    text-shadow: none;
}

input[type="checkbox"]:checked ~ #sidebarMenu {
    transform: translateX(0);
}

.div-navbar-left > input[type=checkbox] {
    box-sizing: border-box;
    display: none;
    transition: all 0.3s;
}

.menu-toggle {
    box-sizing: border-box;
    cursor: pointer;
    height: fit-content;
    transition: all 0.3s;
    width: 50px;
    z-index: 3;
}

.spinner {
    background-color: var(--menu-toggle-color);
    box-sizing: border-box;
    height: 7px;
    position: absolute;
    transition: all 0.3s;
    width: 100%;
}

.horizontal {
    box-sizing: border-box;
    float: left;
    margin-top: 3px;
    position: relative;
    transition: all 0.3s;
}

.diagonal.part-1 {
    box-sizing: border-box;
    float: left;
    position: relative;
    transition: all 0.3s;
}

.diagonal.part-2 {
    box-sizing: border-box;
    float: left;
    margin-top: 3px;
    position: relative;
    transition: all 0.3s;
}

input[type=checkbox]:checked ~ .menu-toggle > .horizontal {
    box-sizing: border-box;
    opacity: 0;
    transition: all 0.3s;
}

input[type=checkbox]:checked ~ .menu-toggle > .diagonal.part-1 {
    box-sizing: border-box;
    margin-top: 17px;
    transform: rotate(135deg);
    transition: all 0.3s;
}

input[type=checkbox]:checked ~ .menu-toggle > .diagonal.part-2 {
    box-sizing: border-box;
    margin-top: -18px;
    transform: rotate(-135deg);
    transition: all 0.3s;
}