@import url('https://fonts.googleapis.com/css?family=Open+Sans');

:root {
    --main-color: #e0e0e0;
    --dark-color: #7f321e;
    --dark-accent-color: #5a2a1a;
    --light-color: #f5f5f5;
    --light-accent-color: #d4d4d4;
    --success-color: #27ae60;
    --error-color: #e74c3c;
    --hilight-color: #008080;
    --warning-color: #f39c12;
    --info-color: #3498db;

    --text-dark: #444444;
    --text-light: #ffffff;
    --text-light-shadow: 3px 3px 2px #333333;
    --text-dark-shadow: 3px 2px 3px #b0b0b0;

    --edit-color: var(--hilight-color);
    --delete-color: var(--error-color);
    --info-color: var(--hilight-color);
    --save-color: var(--hilight-color);
    --cancel-color: var(--dark-color);

    --navbar-height: 95px;
    --category-height: 35px;

    --card-shadow: 0px 4px 8px #000;
    --card-shadow-hover: 0px 8px 16px #000;

    --navigation-border-color: #424242;
}

* {
    box-sizing: border-box;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--text-light);
    -webkit-box-shadow: 0 0 0px 1000px var(--main-color) inset;
    transition: background-color 5000s ease-in-out 0s;
} */

html {
    background: #575859;
    background-repeat: repeat;
    font-family: "Open Sans", sans-serif;
    /* font-size: clamp(0.75rem, 0.375rem + 0.75vw, 1rem); */
    height: 100%;
    overflow: hidden;
}

#root {
    box-sizing: border-box;
}

body {
    margin: 0;
    min-height: 100%;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
}

/* html, body {
    overflow: hidden;
} */

h1 {
    color: var(--text-dark);
    font-size: 2rem;
    font-weight: 900;
    margin: 0 0 20px 0;
    text-align: center;
    text-shadow: var(--text-dark-shadow);
    padding: 0 10px 0 10px;
}

h2 {
    color: var(--text-light);
    font-size: 6rem;
    font-weight: 700;
    margin: 0 0 15px 0;
    text-align: center;
    text-shadow: var(--text-light-shadow);
    padding: 0 7px 0 7px;
}

p {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    color: var(--text-light);
}

a {
    text-decoration: none;
    color: var(--highlight-color);
    color: var(--hilight-color);
}

a:hover {
    color: var(--highlight-hover-color);
    color: #000;
}

button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    color: var(--text-light);
    background-color: var(--hilight-color);
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease-in-out;
}

button:hover {
    background-color: #000;
}

button:disabled {
    background-color: grey;
}

button:disabled:hover {
    background-color: grey;
    cursor: not-allowed;
}

.cancel-button {
    background-color: var(--cancel-color);
}

.create-button {
    width: 50%;
    margin: 0 auto 20px auto;
}

.save-button {
    background-color: var(--save-color);
}

.recipe-action {
    margin-bottom: 15px;
    width: 50%;
}

.info-icon {
    color: var(--info-color);
}

.edit-icon {
    color: var(--edit-color);
}

.delete-icon {
    color: var(--delete-color);
}

.toggle-button {
    padding: 4px 8px;
    width: fit-content;
    font-size: .9rem;
}

.add-button {
    padding: 4px 8px;
    width: fit-content;
    font-size: .9rem;
}

.button-group {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
}

.disable-select {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.enable-select {
    -webkit-tap-highlight-color: auto;
    -webkit-touch-callout: auto;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.error-message {
    color: #e74c3c;
    margin-bottom: 15px;
}

.loading {
    background: linear-gradient(90deg, #d0d0d0 25%, #b0b0b0 50%, #d0d0d0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: -2;
}

.overlay.show {
    opacity: 1;
    pointer-events: all;
    z-index: 998;
}

.sub-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: -3;
    box-sizing: border-box;
    overflow: hidden;
}

.sub-overlay.show {
    opacity: 1;
    pointer-events: all;
    z-index: 998;
    overflow: auto;
    scrollbar-width: none;
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    position: relative;
    border-radius: 5px;
    font-size: 1.5em;
    color: var(--text-dark);
}

label {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0px;
    display: block;
    width: 100%;
}

input {
    border: 1px solid var(--dark-accent-color);
    width: 100%;
    font-size: 1rem;
    padding: 5px 8px;
    border-radius: 5px;
    margin-bottom: 5px;
    transition: border-color 0.3s ease-in-out;
    background-color: var(--light-color);
}

input:focus {
    border-color: var(--hilight-color);
    outline: none;
}

input:read-only {
    background-color: var(--light-color);
    cursor: not-allowed;
}

input:read-only:focus {
    border-color: var(--dark-accent-color);
    outline: none;
    cursor: not-allowed;
}

/* input[type="text"] {
    padding: 5px 8px;
    border-radius: 5px;
    margin-bottom: 5px;
}

input[type="email"] {
    padding: 5px 8px;
    border: 1px solid var(--dark-accent-color);
    border-radius: 5px;
    margin-bottom: 15px;
}

input[type="password"] {
    padding: 5px 8px;
    border: 1px solid var(--dark-accent-color);
    border-radius: 5px;
    margin-bottom: 15px;
} */

input[type="checkbox"] {
    transform: scale(1.5);
    margin-left: 10px;
    transform-origin: center;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    background-color: var(--edit-color);
    border-color: var(--edit-color);
    accent-color: var(--edit-color);
}

input[type="checkbox"]:disabled {
    cursor: not-allowed;
}

input[type="file"] {
    display: none;
}

textarea {
    resize: none;
    height: 150px;
    margin-bottom: 5px;
    border: 1px solid var(--dark-accent-color);
    border-radius: 5px;
    width: 100%;
    transition: border-color 0.3s ease-in-out;
}

textarea:focus {
    border-color: var(--hilight-color);
    outline: none;
}

textarea:read-only {
    background-color: var(--light-color);
    cursor: not-allowed;
}

textarea:read-only:focus {
    border-color: var(--dark-accent-color);
    outline: none;
    cursor: not-allowed;
}
select {
    padding: 5px 8px;
    background-color: #fff;
    border: 1px solid var(--dark-accent-color);
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    margin: 5px 0px 10px 0px;
    color: var(--text-dark);
    transition: border-color 0.3s ease-in-out;
}

select:focus {
    border-color: var(--hilight-color);
    outline: none;
}

select:disabled {
    border: 1px solid var(--dark-accent-color);
    background-color: var(--light-color);
    color: var(--text-dark);
    cursor: not-allowed;
}
