.edit-container {
    width: 95%;
    height: calc(100% - 40px);
    padding: 20px;
    background-color: var(--main-color);
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.9);
    margin: 20px auto;
    display: flex;
    flex-direction: column;
}

.edit-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.edit-list {
    flex-grow: 1;
    overflow-y: auto;
    scrollbar-width: none;
}

.create-form-container {
    /* margin-top: calc(var(--navbar-height) + 30px); */
    background-color: var(--light-color);
    padding: 10px;
    /* border-radius: 8px; */
    /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); */
    text-align: center;
    width: 100%;
    /* max-width: 95%; */
    height: calc(100% - 35px);
}

.create-form-container .error-message {
    font-size: 1.2em;
}

.item-container {
    background-color: var(--light-color);
    padding: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.9);
    text-align: center;
    width: 100%;
    font-size: 1em;
    height: calc(100% - 35px);
    overflow: auto;
    scrollbar-width: none;
}

/* .item-container label {
    
    font-weight: bold;
    margin-bottom: 0px;
    display: block;
} */

.item-container h1 {
    margin-bottom: 10px;
}

.item-container img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.item-container img.editing {
    margin-bottom: 0;
}

.edit-form {
    margin-bottom: 10px;
    border-radius: 0;
}

.edit-form button {
    font-size: .8rem;
}

.edit-form.editing {
    background-color: var(--light-accent-color);
    padding: 5px;
    width: 100%;
}

.ingredients-form {
    display: flex;
    flex-direction: column;
    gap: 0px;
    background-color: var(--light-accent-color);
    padding: 0 5px 5px 5px;
    margin-bottom: 5px;
}

.ingredients-form p {
    font-size: 1.25rem;
}

.ingredients-form input[type="text"]:focus {
    border-color: var(--hilight-color);
    outline: none;
}

.ingredients-form input.quantity:focus {
    border-color: var(--hilight-color);
    outline: none;
}

.ingredients-form input:read-only {
    background-color: var(--light-color);
    cursor: not-allowed;
}

.ingredients-form input:read-only:focus {
    border-color: var(--dark-accent-color);
    outline: none;
    cursor: not-allowed;
}

.ingredients-form select {
    padding: 5px 8px;
    background-color: #fff;
    border: 1px solid var(--dark-accent-color);
    border-radius: 5px;
    font-size: 1rem;
    width: 25%;
    margin: 5px 0px 0 0;
    color: var(--text-dark)
}

.ingredients-form select:focus {
    border-color: var(--hilight-color);
    outline: none;
}

.ingredients-form select:disabled {
    border: 1px solid var(--dark-accent-color);
    background-color: var(--light-color);
    color: var(--text-dark);
    cursor: not-allowed;
}

.ingredients-form button {
    margin-top: 5px;
    font-size: .7rem;
}

.ingredients-form .trash-button {
    background-color: var(--delete-color);
    padding: 5px;
    font-size: 1.1rem;
    margin: 5px 0 0 5px;
}

.ingredients-form .trash-button:hover {
    background-color: #000;
}

.ingredient-form-inputs {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.ingredient-form-inputs > div {
    display: flex;
    gap: 10px;
}

.ingredient-form-inputs > div:first-child {
    flex: 0 0 50%;
}

.ingredient-form-inputs > div input[type="text"] {
    padding: 5px 8px;
    border: 1px solid var(--dark-accent-color);
    border-radius: 5px;
    font-size: 1rem;
    margin: 5px 5px 0 0;
    flex: 1;
}

.ingredient-form-inputs .quantity-row {
    display: flex;
    gap: 0px;
}

.ingredient-form-inputs .quantity-row input[type="text"] {
    max-width: 35%;
    flex: 1;
    gap: 0;
}

.ingredient-form-inputs .quantity-row select {
    flex: 1;
}

.ingredient-form-inputs .quantity-row button {
    flex: 0;
}

@media (max-width: 760px) {
    .ingredients-form {
        flex-direction: column;
    }
    
    .ingredient-form-inputs > div:first-child input[type="text"] {
        margin-right: 0;
    }

    .ingredient-form-inputs {
        flex-direction: column;
    }
}

/* .item-list {
    list-style-type: none;
    padding: 0;
} */

.item-list li:hover {
    background-color: var(--main-color);
    border-color: #000;
}

.list-item {
    color: var(--text-dark);
    position: relative;
    background-color: var(--light-color);
    border: 1px solid var(--dark-color);
    margin-bottom: 10px;
    /* padding: 10px 15px; */
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.list-item-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.item-name {
    flex: 1;
    word-wrap: break-word;
    margin: 10px 15px;
}

.item-name p {
    font-size: 1.2rem;
    cursor: pointer;
    color: var(--text-dark);
}

.item-name.published p {
    color: var(--hilight-color);
}

.item-icons-container {
    margin: 10px 15px;
}

.item-icons-container div {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.item-icons-container p {
    color: var(--text-dark);
}

/* .item-icon {
    margin-left: 10px;
} */

/* .list-item-container {
    width: 100%;
    background-color: green;
} */

.list-item-icons {
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-right: 15px;
}

/* .item-name {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5rem;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    padding: 2px 5px;
} */

.item-icon {
    font-size: 1.5em;
    cursor: pointer;
}

.item-icon.disabled {
    color: grey;
}

.item-icon:hover {
    color: #000;
}

.item-icon.disabled:hover {
    color: grey;
    cursor: not-allowed;
}

/* Style the custom label */
.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: var(--button-color);
    color: var(--button-text-color);
    border: 1px solid var(--button-border-color);
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

/* Hover effect for the custom label */
.custom-file-upload:hover {
    background-color: var(--button-hover-color);
}

/* Disabled state for the custom label */
input[type="file"]:disabled + .custom-file-upload {
    background-color: var(--disabled-color);
    cursor: not-allowed;
    border-color: var(--disabled-border-color);
    color: var(--disabled-text-color);
}

#formBannerImage {
    margin-bottom: 15px;
}

#formBannerImage label {
    display: inline-block;
    padding: 4px 8px;
    cursor: pointer;
    background-color: var(--hilight-color);
    color: var(--text-light);
    /* border: 1px solid var(--button-border-color); */
    border-radius: 5px;
    font-size: .9rem;
    transition: background-color 0.3s ease;
    width: fit-content;
}

#formBannerImage label:hover {
    background-color: #000;
}





/* .item-container .ingredients-form:last-of-type {
    margin-bottom: 20px;
}

.item-container .ingredients-form:last-child {
    margin-bottom: 20px;
}

.item-container .ingredients-form:nth-last-of-type(1) {
    margin-bottom: 15px;
} */

/* .item-container .instructions-form:last-child {
    margin-bottom: 15px;
} */

.edit-form label {
    font-weight: bold;
    margin-bottom: 0px;
    display: block;
    font-size: 1.1rem;
    width: fit-content;
}

.item-container .ingredients-form .button-group {
    justify-content: flex-start;
}

.section-header {
    display: flex;
    justify-content: space-between;
    width:100%;
    background-color: var(--light-accent-color);
    margin-bottom: 5px;
    align-items: center;
}

.section-header label {
    text-align: left;
    margin: 0 0 0 5px;
}

.section-header button {
    align-items: flex-end;
    width: 25%;
    margin: 5px 5px 5px 0;
}

.section-body {
    margin-bottom: 15px;
}

.info-value {
    border-radius: 5px;
    font-size: 1.3rem;
}

.item-container .info-field label {
    font-size: 1.1rem;
}

.item-container .ingredients-button-group {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

/* .item-container .ingredients-button-group button {
    padding: 4px 8px;
    width: fit-content;
    font-size: .9rem;
} */

.item-container .div-confirm .button-group {
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-top: 20px;
}

.item-container-info {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    font-size: 1.5rem;
    color: var(--text-dark);
    width: 100%;
}

.item-container-info .info-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.item-container-info .info-field label {
    font-weight: bold;
    margin-bottom: 5px;
}

.item-container-info .info-value {
    padding: 5px 8px;
    border: 1px solid var(--dark-accent-color);
    border-radius: 5px;
    font-size: 1rem;
    cursor: not-allowed;
}

.item-container-info .info-value:focus {
    outline: none;
}

.item-container .add-button-group {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

.item-container .add-button-group button {
    font-size: .8rem;
}

.instructions-form {
    display: flex;
    flex-direction: column;
    gap: 0px;
    background-color: var(--light-accent-color);
    padding: 0 5px 5px 5px;
    margin-bottom: 5px;
}

.instructions-form textarea {
    resize: none;
    width: 100%;
    height: 50px;
    margin: 5px 0 0 0;
    border: 1px solid var(--dark-accent-color);
    border-radius: 5px;
    scrollbar-width: none;
}

.instructions-form textarea:read-only {
    background-color: var(--light-color);
    cursor: not-allowed;
}

.instructions-form textarea:focus {
    border-color: var(--hilight-color);
    outline: none;
}

.instructions-form textarea:read-only:focus {
    border-color: var(--dark-accent-color);
    background-color: var(--light-color);
    cursor: not-allowed;
}

/* .instructions-form input:read-only {
    background-color: var(--light-color);
    cursor: not-allowed;
}

.instructions-form input:read-only:focus {
    border-color: var(--dark-accent-color);
    outline: none;
    cursor: not-allowed;
} */

.instructions-form-inputs {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.instructions-form button {
    margin-top: 5px;
    font-size: .7rem;
}

.instructions-form-inputs .trash-button {
    background-color: var(--delete-color);
    padding: 5px;
    font-size: 1.1rem;
    margin: 5px 0 0 5px;
    height: fit-content;
}

.item-container .instructions-form .button-group {
    margin-top: 0;
    justify-content: flex-start;
    gap: 5px;
}

.edit-form .checkbox-row {
    display: flex;
    align-items: left;
}

.edit-form .checkbox-row input[type="checkbox"] {
    width: fit-content;
}

.generate-form {
    width: 100%;
    justify-content: center;
}

.generate-form textarea {
    width: 50%;
    height: 75px;
    margin: 15px auto 10px auto;
    scrollbar-width: none;
    border-radius: 0;
}

.generate-form button {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
}

@media screen and (max-width: 760px) {
    .generate-form textarea {
        width: 90%;
    }

    .generate-form button {
        width: 90%;
    }
    
}