.popup-alert p {
    color: var(--text-light);
    font-size: 1.75rem;
    margin: 0;
    padding: 1em;
    text-shadow: var(--text-light-shadow);
}

.popup-alert {
    align-items: center;
    display: inline-block;
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
    /* transform: translateY(-var(--navbar-height)); */
    transition: transform 250ms ease-in-out;
    width: 100%;
    z-index: 9;
    box-shadow: none;
}

.popup-alert.translatein {
    box-shadow: 5px 5px 10px var(--dark-color);
    transform: translateY(var(--navbar-height));
}

.popup-alert .alert-error {
    background: var(--error-color);
}

.popup-alert .alert-success {
	background: var(--success-color);
}

.popup-alert .div-close {
    color:var(--light-color);
    cursor: pointer;
    display: flex;
    font-size: 2.5rem;
    font-weight:900;
    justify-items: flex-start;
    line-height: 1em;
    padding-right: 5px;
    position: absolute;
    right: 0;
    text-shadow: var(--text-light-shadow);
    top: 0;
}

.popup-alert .div-close:hover{
    color: #000;
    text-shadow: none;
}