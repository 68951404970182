.card-overlay {
    position: fixed;
    top: var(--navbar-height);
    left: 0;
    width: 100%;
    height: calc(100% - var(--navbar-height) + var(--category-height));
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: -3;
    box-sizing: border-box;
}

.card-overlay.show {
    opacity: 1;
    pointer-events: all;
    z-index: 998;
    /* overflow: auto; */
    /* scrollbar-width: none; */
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
    z-index: 2;
    padding-top: calc(var(--category-height) + 20px);
    box-sizing: border-box;
}

.card {
    position: relative;
    border-radius: 10px;
    border: 10px solid var(--light-color);
    box-shadow: var(--card-shadow);
    width: 300px;
    height: 285px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    background-color: var(--light-color);
    z-index: 3;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

@media screen and (max-width: 760px) {
    .card {
        width:100%;
        margin: 0 20px 90px 20px;
    }

    .card:last-child {
        margin-bottom: 20px;
    }
    
}

.card:hover {
    box-shadow: var(--card-shadow-hover);
    cursor: pointer;
}

/* .card-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(100, 100, 100, 0.7);
    z-index: 1;
} */

.card-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 150px;
    width: 100%;
    z-index: 0;
}

.card-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

/* Styling for the text inside the card */
.card-content h2 {
    font-size: 1.6rem;
    color: #000;
    text-shadow: var(--text-dark-shadow);
    padding: 5px;
    margin: 0 0 5px 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-container .card-content p {
    font-size: .9rem;
    color: var(--text-dark);
    font-weight: 600;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.recipe-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: var(--light-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 3;
    height: calc(100% - 35px);
    overflow: auto;
    scrollbar-width: none;
}

.recipe-detail-banner {
    width: 100%;
    height: 250px;
}

.recipe-detail-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recipe-detail-content {
    padding: 10px 0 0 0;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
}

.recipe-detail-content h1 {
    font-size: 2rem;
    color: var(--text-dark);
    text-shadow: var(--text-dark-shadow);
    margin: 0 0 10px 0;
    padding: 0;
}

.section-edit .recipe-detail-content p {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: var(--text-dark);
    padding: 0;
    font-weight: 600;
}

.section-display .recipe-detail-content p {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: var(--text-dark);
    padding: 0;
    font-weight: 600;
}

/* Instructions section */
.recipe-detail-instructions {
    margin-bottom: 20px;
}

/* Instructions title */
.recipe-detail-instructions h3 {
    font-size: 1.4rem;
    margin-bottom: 5px;
    color: var(--text-dark);
}

/* Instructions list */
.recipe-detail-instructions ul {
    list-style-type: decimal;
    padding-left: 20px;
}

/* Individual instruction item */
.recipe-detail-instructions li {
    font-size: 1rem;
    margin-bottom: 10px;
    color: var(--text-dark);
    font-weight: 600;
}

.recipe-detail-ingredients h3 {
    font-size: 1.4rem;
    margin-bottom: 5px;
    color: var(--text-dark);
}

.ingredients-list {
    column-count: 2;
    column-gap: 20px;
}

.ingredients-list li {
    font-size: .9rem;
    color: var(--text-dark);
    font-weight: 400;
}

.div-display-close {
    position: fixed;
    top: var(--navbar-height);
    right: 10px;
    z-index: 4;
    cursor: pointer;
    color: var(--hilight-color);
    font-size: 2rem;
    font-weight: 900;
    text-shadow: var(--text-dark-shadow);
}

.div-display-close:hover {
    color: #000;
    text-shadow: none;
}

.category-container {
    position: absolute;
    top: var(--navbar-height);
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: var(--category-height);
    z-index: 10;
    /* gap: 10px; */
}

.category-container-floating {
	box-shadow: 0px 5px 10px #000;
}

.category-container-hide {
    z-index: 5;
}

.category-container button {
    background-color: var(--dark-color);
    border-top: 1px solid var(--navigation-border-color);
    border-bottom: 1px solid var(--navigation-border-color);
    border-radius: 0;
    color: var(--text-light);
    cursor: pointer;
    font-size: clamp(0.75rem, 0.375rem + 0.75vw, 1rem);
    text-shadow: var(--text-light-shadow);
    flex-grow: 1;
    flex-basis: 0;
    white-space: nowrap;
}

.category-container button:not(:last-child) {
    border-right: 1px solid var(--navigation-border-color);
}

.category-container button:hover {
    background-color: var(--dark-color);
    color: var(--text-light);
}

.category-container button.selected {
    background-color: #000;
    color: var(--text-light);
    text-shadow: none;
}

.category-container .category-button-placeholder {
    background-color: var(--dark-color);
    border: 1px solid var(--dark-color);
    border-radius: 0;
    color: var(--text-dark);
    cursor: pointer;
    font-size: clamp(0.75rem, 0.375rem + 0.75vw, 1rem);
    text-shadow: var(--text-dark-shadow);
    flex-grow: 1;
    flex-basis: 0;
    white-space: nowrap;
}

.category-container .category-button-placeholder:hover {
    background-color: var(--dark-color);;
    color: var(--text-light);
    text-shadow: none;
    border: 1px solid var(--dark-color);
}

/* @media (max-width: 600px) {
    .card {
        max-width: 100%;
    }
} */
