.fresh-form-container {
    width: 100%;
}

.fresh-form-container p {
    color: var(--text-dark);
}

.fresh-form-container .form-login button {
    width: fit-content;
}

.fresh-container {
    width: 400px;
    max-width: 90%;
    top: 20px;
    left: 50%;
    transform: translatex(-50%);
    padding: 20px;
    background-color: var(--main-color);
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.9);
    text-align: center;
    z-index: 2;
    position: absolute;
}