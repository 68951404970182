:root {
    --menu-border: 3px solid var(--navigation-border-color);
    --a-border: 1px solid var(--navigation-border-color);
    --sidebar-menu-shadow: 0px 5px 10px var(--navigation-border-color);
}

.div-sidebar-menu {
    background: var(--dark-color);
    border-top: var(--menu-border);
    position: absolute;
    top: var(--navbar-height);
    left: 0;
    transform: translateX(-103vw);
    transition: transform 250ms ease-in-out;
    width: 100%;
    z-index: 20;
}

.div-sidebar-menu.translatein {
    box-shadow: var(--sidebar-menu-shadow);
    transform: translateX(0);
}

.div-sidebar-menu a {
    border-bottom: var(--a-border);
    cursor: pointer;
    color: var(--light-color);
    display: block;
    font-size: 1.5rem;
    line-height: 3rem;
    padding: 0px 10px 0px 10px;
	text-decoration: none;
    text-shadow: var(--text-light-shadow);
}

.div-sidebar-menu a:hover, .div-sidebar-menu a:focus {
	background-color: var(--light-accent-color);
    box-shadow: var(--sidebar-menu-shadow);
    color: var(--text-dark);
    text-shadow: none;
}

.div-sidebar-menu button {
    border: none;
    border-bottom: var(--a-border);
    cursor: pointer;
    color: var(--light-color);
    display: block;
    font-size: 1.5rem;
    line-height: 3rem;
    padding: 0px 10px 0px 10px;
    background: none;
    text-decoration: none;
    text-shadow: var(--text-light-shadow);
    width: 100%;
    text-align: left;
    transition: none;
    border-radius: 0;
}

.div-sidebar-menu button:hover, .div-sidebar-menu button:focus {
    background-color: var(--light-accent-color);
    box-shadow: var(--sidebar-menu-shadow);
    color: var(--text-dark);
    text-shadow: none;
    outline: none; /* Remove the focus outline */
}
