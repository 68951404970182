.div-confirm {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 400px;
    max-width: 80%;
    /* margin-top: calc(var(--navbar-height) + 20px); */
    height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.div-confirm p {
    color: var(--text-dark);
}

.div-confirm .button-group {
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-top: 20px;
}

/* .div-confirm .confirm-button {
    background-color: var(--error-color);
} */
