.login-container {
    max-width: 80%;
    width: 400px;
    padding: 20px;
    background-color: var(--main-color);
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.9);
    margin: 20px auto 0 auto;
    display: flex;
    flex-direction: column;
}

.login-container p {
    text-align: center;
    margin-top: 15px;
    font-size: 1rem;
    color: var(--text-dark);
}

.form-login label {
    position: relative;
    top: 12px;
    background-color: transparent;
    padding: 0 7px;
    font-size: 20px;
    color: var(--hilight-color);
}

.form-login input {
    padding: 10px 15px;
    font-size: 1.7rem;
}

.persist-check {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    font-size: 1rem;
    color: var(--text-dark);
    width: 75%;
}

.persist-check label {
    top: 0;
    text-align: left;
    width: fit-content;
    padding: 0;
}

.persist-check input[type="checkbox"] {
    width: 10%;
}

.persist-check input[type="checkbox"]:checked {
    background-color: var(--edit-color);
    border-color: var(--edit-color);
    accent-color: var(--edit-color);
}

.login-btn {
    width: 100%;
    padding: 10px 15px;
}
