.div-main {
    display: block;
	left: 0;
    position: absolute;
	top: 0;
	z-index: 1;
	width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}

.div-main-body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(100, 100, 100, 0.8);
    z-index: 1;
}

.div-main-body {
    background-image: url('/public/banner.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: var(--text-light);
	display: block;
	margin: 0 auto;
	margin-top: calc(var(--navbar-height));
    height: calc(100% - var(--navbar-height));
    position: relative;
	text-align: center;
    z-index: 0;
    box-sizing: border-box;
}

section {
    position: absolute;
    width: 100%;
    height: calc(100% - var(--navbar-height));
    top: var(--navbar-height);
    scroll-margin-top: var(--navbar-height);
    overflow-y: auto;
    scrollbar-width: none;
    z-index: 5;
}

.section-edit {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    text-align: left;
    /* top: var(--navbar-height);
    height: calc(100% - var(--navbar-height)); */
}

.section-display {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    text-align: center;
}

.section-display.loading {
    background: linear-gradient(90deg, rgba(208, 208, 208, 0.2) 25%, rgba(176, 176, 176, 0.2) 50%, rgba(208, 208, 208, 0.2) 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}

.section-display p {
    margin-top: 10px;
    padding: 0 20px;
    color: var(--text-light);
    font-size: 1.4rem;
    font-weight: 400;
}

.section-unauthorized {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    text-align: center;
}

.section-unauthorized h1 {
    color: var(--text-light);
}

.welcome-container {
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100%;
    height: fit-content;
    padding-top: var(--category-height);
    box-sizing: border-box;
}

.welcome-container p {
    margin-top: 0;
    padding: 20px 40px;
    font-size: 1.5rem;
}

.no-recipes-container {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100%;
    height: fit-content;
    padding-top: var(--category-height);
    box-sizing: border-box;
}

.install-prompt {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--dark-color);
    color: var(--text-light);
    padding: 10px;
    text-align: center;
    z-index: 10;
    box-sizing: border-box;
}

.install-prompt p {
    font-size: 1rem;
    font-weight: 400;
}

.install-prompt .button-group {
    justify-content: center;
    margin-top: 10px;
}

.install-prompt .button-group button {
    font-size: .7rem;
}

/* section p {
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    color: #000;
} */